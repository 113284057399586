import {config, dom, library} from '@fortawesome/fontawesome-svg-core'
import {faBars, faCircleXmark, faCog, faDesktop, faEdit, faHeart, faPauseCircle, faPlay, faPlayCircle, faPlus, faSignOutAlt, faSpinner, faStar, faTimes, faTrash,} from '@fortawesome/free-solid-svg-icons'

config.mutateApproach = 'sync';

library.add(
    faSpinner,
    faPlayCircle,
    faPlay,
    faPauseCircle,
    faHeart,
    faTimes,
    faCog,
    faSignOutAlt,
    faPlus,
    faEdit,
    faTrash,
    faDesktop,
    faStar,
    faBars,
    faCircleXmark
);

dom.watch();
