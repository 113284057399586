import Trix from 'trix';

Trix.config.textAttributes.small = {tagName: "small", inheritable: true};

addEventListener("trix-initialize", function (event) {
    let element = event.target;
    let editor = element.editor;
    let toolbarElement = element.toolbarElement;
    let groupElement = toolbarElement.querySelector(".trix-button-group--text-tools");

    groupElement.insertAdjacentHTML("beforeend", '<button type="button" data-trix-attribute="small" title="small" class="trix-button"><small>small</small></button>');

    let selectedAttributes = new Set;

    function updateSelectedAttributes() {
        selectedAttributes = new Set;

        let selectedRange = editor.getSelectedRange();
        if (selectedRange[0] === selectedRange[1]) {
            selectedRange[1]++
        }

        let selectedPieces = editor.getDocument().getDocumentAtRange(selectedRange).getPieces();
        selectedPieces.forEach(function (piece) {
            Object.keys(piece.getAttributes()).forEach(function (attribute) {
                selectedAttributes.add(attribute)
            })
        })
    }

    updateSelectedAttributes();
    element.addEventListener("trix-selection-change", updateSelectedAttributes);
});