document.addEventListener('turbo:load', event => {
    if (typeof gtag === 'function') {
        gtag('config', 'UA-53059619-5', {
            'page_location': event.detail.url,
            'anonymize_ip': true
        });
    }

    if (typeof fbq === 'function') {
        fbq('init', '1759810280965997');
        fbq('track', 'PageView');
    }
});
