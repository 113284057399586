import {Controller} from "@hotwired/stimulus"
import Cookies from "js-cookie"

export class FavoritesController extends Controller {
    static targets = ["link"]

    connect() {
        let favorites = JSON.parse(Cookies.get("favorites") || "[]")

        if (!Array.isArray(favorites)) {
            favorites = []
        }

        let dubber_id = parseInt(this.data.get("dubber"));
        let dubber_index = favorites.indexOf(dubber_id);

        if (dubber_index !== -1) {
            this.linkTarget.classList.add("active")
        }
    }

    toggle(event) {
        event.preventDefault();
        let favorites = JSON.parse(Cookies.get("favorites") || "[]")

        if (!Array.isArray(favorites)) {
            favorites = [];
        }

        let dubber_id = parseInt(this.data.get("dubber"));
        let dubber_index = favorites.indexOf(dubber_id);

        if (dubber_index === -1) {
            favorites.push(dubber_id)
        } else {
            favorites.splice(dubber_index, 1);
        }

        this.linkTarget.classList.toggle("active");

        Cookies.set("favorites", JSON.stringify(favorites), {path: "/", expires: 365})
    }

    remove(event) {
        let dubber_id = parseInt(this.data.get("dubber"));
        document.querySelectorAll(`#dubber_${dubber_id}, [data-bs-target='#dubber_${dubber_id}']`).forEach((el) => {
            if (el) {
                el.classList.add("fade")
                setTimeout(() => el.remove(), 300)
            }
        })
        this.toggle(event)
    }
}
