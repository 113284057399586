import {Controller} from "@hotwired/stimulus"
import WaveSurfer from "wavesurfer.js";

export class CastingController extends Controller {
    static targets = ['play', 'pause', 'player', 'status']

    connect() {
        this.wavesurfer = WaveSurfer.create({
            container:     this.playerTarget,
            waveColor:     'purple',
            progressColor: 'violet',
            backend:       'MediaElement',
            cursorWidth:   0,
            height:        38,
            hideScrollbar: true,
        });

        if (this.data.has('url')) {
            this.statusTarget.classList.remove('d-none');
            this.wavesurfer.load(this.data.get('url'));
            this.wavesurfer.on('ready', () => {
                this.statusTarget.classList.add('d-none');
            });
            if (this.hasPlayTarget) {
                this.playTarget.classList.remove('disabled');
            }
            if (this.hasPauseTarget) {
                this.pauseTarget.classList.remove('disabled');
            }
        }
    }

    disconnect() {
        this.wavesurfer.destroy();
    }

    play() {
        if (this.hasPlayTarget) {
            this.playTarget.classList.add('d-none');
            this.playTarget.classList.remove('d-block');
        }
        if (this.hasPauseTarget) {
            this.pauseTarget.classList.add('d-block');
            this.pauseTarget.classList.remove('d-none');
        }

        this.wavesurfer.play();
    }

    pause() {
        if (this.hasPlayTarget) {
            this.playTarget.classList.add('d-block');
            this.playTarget.classList.remove('d-none');
        }
        if (this.hasPauseTarget) {
            this.pauseTarget.classList.add('d-none');
            this.pauseTarget.classList.remove('d-block');
        }
        this.wavesurfer.pause();
    }
}
