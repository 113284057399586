import {Controller} from "@hotwired/stimulus";

export class InquiryController extends Controller {
    static targets = ["briefing"];

    onChange(e) {
        if (e.target.value) {
            this.briefingTarget.classList.add("d-none");
        } else {
            this.briefingTarget.classList.remove("d-none");
        }
    }
}
