import {Controller} from "@hotwired/stimulus"
import TomSelect from "tom-select";
import {get} from "@rails/request.js"

export class TomSelectController extends Controller {
    static values = {
        followUrl:   String,
        preloadUrl:  String,
        selectedIds: Array,
        valueField:  {type: String, default: "id"},
        labelField:  {type: String, default: "name"},
        searchField: {type: Array, default: ["name"]},
        sortField:   {type: Array, default: [{field:"name"},{field:"$score"}]},
    }

    connect() {
        this.tomSelect = new TomSelect(this.element, {
            maxOptions:       null,
            closeAfterSelect: true,
            ...this.preload()
        })
    }

    disconnect() {
        this.tomSelect.destroy()
    }

    follow(e) {
        Turbo.visit(`${this.followUrlValue}/${e.target.value}`)
    }

    preload() {
        if (!this.hasPreloadUrlValue) {
            return {}
        }

        return {
            preload:     true,
            valueField:  this.valueFieldValue,
            labelField:  this.labelFieldValue,
            searchField: this.searchFieldValue,
            sortField:   this.sortFieldValue,
            load:        async (query, callback) => {
                if (this.tomSelect.loading > 1) {
                    callback()
                    return
                }

                const response = await get(this.preloadUrlValue, {contentType: "application/json", responseKind: "json"})
                if (!response.ok) {
                    callback()
                    return
                }
                callback(await response.json)
                this.tomSelect.settings.load = null
            },
            onLoad:      () => {
                if (this.hasSelectedIdsValue) {
                    this.selectedIdsValue.forEach(id => this.tomSelect.addItem(id, true))
                }
            }
        }
    }
}
