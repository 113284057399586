import WaveSurfer from 'wavesurfer.js';
import { Controller } from "@hotwired/stimulus"

export class AudioPlayerController extends Controller {
    static targets = ['status', 'player', 'startlink', 'playlink', 'pauselink']

    connect() {
        this.wavesurfer = WaveSurfer.create({
            container: this.playerTarget,
            waveColor: 'violet',
            progressColor: 'purple',
            backend: 'MediaElement',
            cursorWidth: 0
        });

        this.wavesurfer.on('ready', () => {
            this.statusTarget.classList.add('d-none');
        });

        this.wavesurfer.on('play', () => {
            this.statusTarget.classList.add('d-none');
        });
    }

    disconnect() {
        this.wavesurfer.destroy();
    }

    start(event) {
        event.preventDefault();
        this.wavesurfer.on('ready', () => {
            this.wavesurfer.play();
        });

        this.wavesurfer.setWaveColor(event.currentTarget.dataset.wavecolor);
        this.wavesurfer.setProgressColor(event.currentTarget.dataset.progresscolor);

        this.statusTarget.classList.remove('d-none');
        this.wavesurfer.load(event.currentTarget.dataset.link);
    }

    play(event) {
        event.preventDefault();
        this.wavesurfer.play();
    }

    pause(event) {
        event.preventDefault();
        this.wavesurfer.pause();
    }

    reset_buttons() {
        this.playlinkTargets.forEach(function (x) {
            x.classList.add('d-none');
        });
        this.pauselinkTargets.forEach(function (x) {
            x.classList.add('d-none');
        });
        this.startlinkTargets.forEach(function (x) {
            x.classList.remove('d-none');
        });
    }
}
