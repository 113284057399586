// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"
import "trix"
import "@rails/actiontext"

import "./fontawesome"
import "./trix"
import "./google-analytics"

document.addEventListener("turbo:load", function () {
    let firstTab = document.querySelector("div[data-bs-toggle=tab]:first-child")
    if (firstTab) {
        bootstrap.Tab.getOrCreateInstance(firstTab).show()
    }

    document.querySelectorAll(".static a:not(#cookie-settings)").forEach(function (element) {
        element.target = "_blank";
    })

    document.querySelectorAll("audio").forEach(function (element) {
        element.outerHTML = element.outerHTML
    })
});
