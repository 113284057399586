import {Controller} from "@hotwired/stimulus"

export class AudioButtonController extends Controller {
    static targets = ['pauselink', 'playlink', 'startlink']

    get audioPlayerController() {
        let audioPlayerElement = document.querySelector('[data-controller="audioplayer"]')
        return this.application.getControllerForElementAndIdentifier(audioPlayerElement, 'audioplayer')
    }

    connect() {
        this.pauselinkTarget.classList.add('d-none');
        this.playlinkTarget.classList.add('d-none');

        if (this.startlinkTarget.dataset.preload === 'true') {
            this.audioPlayerController.statusTarget.classList.remove('d-none');
            this.audioPlayerController.wavesurfer.load(this.startlinkTarget.dataset.link);
            this.audioPlayerController.wavesurfer.setWaveColor(this.startlinkTarget.dataset.wavecolor);
            this.audioPlayerController.wavesurfer.setProgressColor(this.startlinkTarget.dataset.progresscolor);
            this.pauselinkTarget.classList.add('d-none');
            this.startlinkTarget.classList.add('d-none');
            this.playlinkTarget.classList.remove('d-none');
        }
    }

    disconnect() {
    }

    start(event) {
        event.preventDefault();
        this.audioPlayerController.reset_buttons();

        this.pauselinkTarget.classList.remove('d-none');
        this.startlinkTarget.classList.add('d-none');
        this.playlinkTarget.classList.add('d-none');
    }

    play(event) {
        event.preventDefault();
        this.audioPlayerController.reset_buttons();

        this.pauselinkTarget.classList.remove('d-none');
        this.startlinkTarget.classList.add('d-none');
        this.playlinkTarget.classList.add('d-none');
    }

    pause(event) {
        event.preventDefault();
        this.audioPlayerController.reset_buttons();

        this.pauselinkTarget.classList.add('d-none');
        this.startlinkTarget.classList.add('d-none');
        this.playlinkTarget.classList.remove('d-none');
    }
}
