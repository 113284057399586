import {Controller} from "@hotwired/stimulus"
import {patch} from '@rails/request.js'
import Sortable from 'sortablejs'

export class SortableController extends Controller {
    static values = {
        handle:       String,
        resourceName: String,
        paramName:    String
    }

    initialize() {
        this.onEnd = this.onEnd.bind(this)
    }

    connect() {
        this.sortable = Sortable.create(this.element, {
            animation: 150,
            handle:    this.handleValue || undefined,
            onEnd:     this.onEnd
        })
    }

    async onEnd({item, newIndex}) {
        if (!item.dataset.sortableUpdateUrl) return

        const param = `${this.resourceNameValue}[${this.paramNameValue}]`

        const data = new FormData()
        data.append(param, newIndex)

        await patch(item.dataset.sortableUpdateUrl, {body: data, responseKind: "json"})
    }
}
